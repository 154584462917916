import {
  Grid,
  // Tooltip,
  Typography,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomDatePicker from 'components/DatePicker';
import ProjectSkillsAddSvg from 'components/SvgAsCode/ProjectSkillsAddSvg';
import ProjectSkillsTrashSvg from 'components/SvgAsCode/ProjectSkillsTrashSvg';
import React from 'react';
import { Controller, useFieldArray, UseFormReturn } from 'react-hook-form';
import { GeneralDetails } from 'types';

type Props = {
  control: UseFormReturn<GeneralDetails>;
  readOnly?: boolean;
};

export const EducationInfo: React.FC<Props> = (props: Props) => {
  const { control: formControl, readOnly } = props;
  const { control, formState } = formControl;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'educations',
  });

  const removeField = (idx: number) => {
    remove(idx);
  };
  const addField = () => {
    append({
      college: '',
      country: '',
      degree: '',
      graduationYear: '',
      university: '',
    });
  };
  return (
    <Typography component={'div'}>
      {fields && fields.length ? (
        fields.map((item, index: number) => (
          <Grid
            container
            spacing={1}
            key={item.id}
            sx={
              fields.length === index + 1
                ? {}
                : {
                    borderBottom: '1px solid #ababff',
                    marginBottom: '10px',
                    paddingBottom: '10px',
                  }
            }>
            <Grid item md={3} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Degree
                <span className="red-asterisk"> *</span>
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`educations.${index}.degree`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className="custom-Input"
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(
                            e?.target?.value
                              .replace(/[^a-zA-Z\s]/g, '')
                              .trimStart(),
                          );
                          formControl.trigger(`educations.${index}.degree`);
                        }}
                        disabled={readOnly ? true : false}
                        error={
                          formState.errors?.educations?.[index]?.degree?.message
                        }
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={2.5} sm={12}>
              <Typography
                component={'label'}
                sx={{
                  alignItems: 'center',
                  display: 'flex !important',
                  gap: '2px',
                }}>
                {/* <Tooltip title={'Year of Graduation'} placement="top"> */}
                <Typography component={'label'} className="input-title pb-2">
                  Year of Graduation
                </Typography>
                <span
                  className="red-asterisk"
                  style={{
                    fontSize: '12px',
                  }}>
                  *
                </span>
                {/* </Tooltip> */}
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`educations.${index}.graduationYear`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomDatePicker
                        views="YYYY"
                        className="custom-datepicker"
                        name={name}
                        onChange={(e) => {
                          onChange(e);
                          formControl.trigger(
                            `educations.${index}.graduationYear`,
                          );
                        }}
                        value={value}
                        disabled={readOnly ? true : false}
                        error={
                          formState.errors?.educations?.[index]?.graduationYear
                            ?.message
                        }
                        {...ref}
                        readOnly
                        isFormating={true}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={3.1} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                University
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`educations.${index}.university`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className="custom-Input"
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(
                            e?.target?.value
                              .replace(/[^a-zA-Z\s]/g, '')
                              .trimStart(),
                          );
                        }}
                        disabled={readOnly ? true : false}
                        error={
                          formState.errors?.educations?.[index]?.university
                            ?.message
                        }
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={2} sm={12}>
              <Typography component={'label'} className="input-title pb-2">
                Country
              </Typography>
              <Typography component={'div'}>
                <Controller
                  control={control}
                  defaultValue=""
                  name={`educations.${index}.country`}
                  render={({ field: { onChange, value, name, ref } }) => {
                    return (
                      <CustomInput
                        placeHolder=""
                        value={value}
                        name={name}
                        className="custom-Input"
                        ref={ref}
                        onChange={(e: any) => {
                          onChange(
                            e?.target?.value
                              .replace(/[^a-zA-Z\s]/g, '')
                              .trimStart(),
                          );
                        }}
                        error={
                          formState.errors?.educations?.[index]?.country
                            ?.message
                        }
                        disabled={readOnly ? true : false}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>

            {!readOnly && (
              <Grid item md={1.2} sm={12} sx={{ marginLeft: 'auto' }}>
                <Typography
                  component={'label'}
                  className="input-title pb-2"
                  sx={{ opacity: 0 }}
                />
                <Typography
                  component={'div'}
                  className="flex-row"
                  style={{ transform: 'translateY(16px)' }}>
                  {fields.length == 1 && index <= 0 ? null : (
                    <Typography
                      component="div"
                      className="trashicon"
                      onClick={() => {
                        removeField(index);
                      }}>
                      <ProjectSkillsTrashSvg />
                    </Typography>
                  )}
                  {fields.length - 1 === index ? (
                    <Typography
                      component="div"
                      className="trashicon"
                      onClick={() => {
                        if (addField) {
                          addField ? addField() : null;
                        }
                      }}>
                      <ProjectSkillsAddSvg />
                    </Typography>
                  ) : null}
                </Typography>
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <Typography
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
          }}>
          <Typography
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              fontSize: '14px !important',
              fontWeight: '500 !important',
              justifyContent: 'center',
              textAlign: 'center',
            }}>
            Education details not identified. Please click the &quot;Add&quot;
            button to include them.
          </Typography>

          <Typography
            component="div"
            className="trashicon"
            sx={{ marginLeft: 'auto' }}
            onClick={() => {
              if (!readOnly && addField) {
                addField ? addField() : null;
              }
            }}>
            <ProjectSkillsAddSvg />
          </Typography>
        </Typography>
      )}
    </Typography>
  );
};
